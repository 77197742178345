import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Helmet from 'react-helmet'

import Layout, { Footer } from '../components/Layout'
import bigLogo from '../images/big_logo.svg'

const IndexPage = () => (
  <Layout>
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: parallaxScript,
        },
      ]}
    />
    <Background />
    <LogoSection>
      <img alt="logo" src={bigLogo} />
    </LogoSection>
    <Vision>
      <div>
        <h1>Sciigo</h1>
        <p>
          Vi banar väg för hållbara fastigheter genom att samla och organisera
          hållbarhetsdata för alla världens byggnader
        </p>
      </div>
    </Vision>
    <SplitSection>
      <div>
        <h2>Sustainability Reporting</h2>
        <p>
          Samla och kommunicera hållbarhetsdata för en fastighet eller en
          portfölj med syfte t.ex. att kvalificera för grön finansiering
        </p>
      </div>
      <div>
        <h2>Sustainability Intelligence</h2>
        <p>
          Analysera hållbarhetsdata för att vinna insikter, prioritera åtgärder
          och sänka kapitalkostnader samt höja ROI för investeringar i
          hållbarhet
        </p>
      </div>
    </SplitSection>
    <SplitSection>
      <div>
        <h2>För Finansiella Institutioner</h2>
        <ul>
          <li>Överblick över hållbarhetsdata i portföljen</li>
          <li>Automatisk kvalificering för gröna kreditprodukter</li>
          <li>Förenklad intern och extern rapportering</li>
          <li>
            Verifierad hållbarhetsdata för att stötta riskanalys,
            produktutveckling, prissättning, marknadsföring, etc.
          </li>
        </ul>
      </div>
      <div>
        <h2>För Fastighetsägare</h2>
        <ul>
          <li>Överblick över hållbarhetsdata i portföljen</li>
          <li>
            Automatisk kvalificering för grön finansiering—lägre kapitalkostnad
          </li>
          <li>
            Konkreta värdefulla insikter—bättre ROI för investeringar i
            hållbarhet
          </li>
        </ul>
      </div>
    </SplitSection>
    <Footer>
      <menu>
        <div>
          <a href="./articles">Artiklar</a>
        </div>
        <div>|</div>
        <div>
          <a href="mailto:hello@sciigo.com">hello@sciigo.com</a>
        </div>
      </menu>
    </Footer>
  </Layout>
)

export default IndexPage

const BackgroundContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #e4e4ef;
  overflow: hidden;
  z-index: 0;
  picture {
    position: absolute;
    display: flex;
    left: 0;
    width: 100vw;

    @media (max-width: 750px) {
      height: 1200px;
    }

    img {
      object-fit: cover;
      object-position: top center;
      height: auto;
      width: 100%;

      @media (min-width: 1000px) {
        object-position: center -100px;
      }
    }
  }
`

const Background = props => (
  <BackgroundContainer>
    <picture id="bg">
      <source
        type="image/avif"
        srcSet="
            background-1000.avif 1000w,
            background-1600.avif 1600w,
            background.avif      2973w
          "
      />
      <source
        type="image/webp"
        srcSet="
            background-1000.webp 1000w,
            background-1600.webp 1600w,
            background.webp      2973w
          "
      />
      <img
        id="bg-img"
        srcSet="
            background-1000.jpg 1000w,
            background-1600.jpg 1600w,
            background.jpg      2973w
          "
        sizes="100vw"
        src="background.jpg"
        alt="Väg vid kanal bland skyskrapor"
      />
    </picture>
  </BackgroundContainer>
)

const Section = styled.section`
  position: relative;
  box-sizing: border-box;
  h1 {
    color: white;
  }

  @media (max-width: 750px) {
    h1,
    h2 {
      font-size: 1.8rem;
      letter-spacing: -0.1rem;
    }
  }
`

const LogoSection = styled(Section)`
  padding: 6rem 0;
  img {
    height: 20rem;
    max-width: 90vw;
  }

  @media (max-width: 750px) {
    padding: 3rem 0;
  }
`

const Vision = styled(Section)`
  padding: 1rem;
  background-color: #009800;
  color: white;
  p {
    font-size: 1.5rem;
    @media (max-width: 750px) {
      font-size: 1rem;
    }
  }
`

const SplitSectionWrapper = styled(Section)`
  position: relative;
  margin: 6rem 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;

  > div:first-child,
  > div:last-child {
    background-color: white;
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    max-width: 500px;
    padding: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    background-color: transparent;
    > div:first-child,
    > div:last-child {
      margin: 0 !important;
      max-width: 100vw !important;
    }
  }
`

const Spacer = styled.div`
  background-color: ${theme('colors.green')};
  width: 3rem;
  @media (max-width: 750px) {
    background-color: transparent;
    height: 3rem;
    padding: 0;
  }
`

const SplitSection = ({ children }) => (
  <SplitSectionWrapper>
    {children[0]}
    <Spacer />
    {children[1]}
  </SplitSectionWrapper>
)

const parallaxScript = `
const adjustParallaxBg = () => {
  window.requestAnimationFrame(() => {
    const bg = document.getElementById('bg')
    const bgImg = document.getElementById('bg-img')
    const factor = Math.min(
      0.8,
      (bgImg.offsetHeight - window.innerHeight) /
        (document.body.offsetHeight - window.innerHeight)
    )
    bg.style.top = \`\${-window.scrollY * factor}px\`
  })
}

document.addEventListener('scroll', adjustParallaxBg)
window.addEventListener('resize', adjustParallaxBg)
`
